//スライダーリンク（JS）

@import "slick-arrow";

.sliderLink {
  padding-bottom: 0;
  margin: 0 -20px 30px;
  @include mq-max(sm) {
    margin: 0;
  }

  &_component {}

  .slider {

    &_item {
      width: 100%;
      padding: 0 18px 20px;
      &>a {
        display: block;
        box-shadow:0px 3px 6px 3px rgba(0,0,0,0.16);
        border-radius: 5px;
        overflow: hidden;
        &:hover {
          .slider_thumb {
            img {
              transform: scale(1.2);
            }
          }
          .readmore {
            color: #000;
            &:after {
              right: -4px;
            }
          }
        }
      }
    }

    &_thumb {
      line-height: 1;
      position: relative;
      overflow: hidden;
      img {
        width: 100%;
        display: block;
        transition: transform $transition;
      }
    }

    &_body {
      padding: 20px 30px 50px;
      position: relative;
      background-color: #fff;
      &:before {
        content: '';
        background: url(/inc/image/common/ico/ico_truck.png)no-repeat center / contain;
        width: 40px;
        height: 40px;
        display: inline-block;
        position: absolute;
        top: -20px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
      }
      .readmore {
        position: absolute;
        right: 30px;
        bottom: 20px;
        line-height: 1;
        color: $keyColor;
        font-weight: 600;
        font-size: 14px;
        transition: color $transition;
        padding-right: 18px;
        &:after {
          @include fa('f101');
          position: absolute;
          top: 54%;
          right: 0;
          transform: translateY(-50%);
          transition: right $transition;
        }
      }
    }

    &_title {
      font-family: $sanserif;
      font-size: 24px;
      text-align: center;
      color: #000;
      margin-bottom: 10px;
      @include mq-max(xs) {
        font-size: 20px;
      }
      &>span {
        display: block;
        font-size: 12px;
      }
      &+p {
        margin: 0;
      }
    }

  }

  //slick
  .slick-dots {
    li {
      &.slick-active {
        button {
          background-color: transparent;
          border: 2px solid $keyColor;
          border-radius: 50px;
          width: 8px;
          height: 8px;
          display: block;
          text-indent: -9999em;
          padding: 0;
          margin: 0 auto;
          position: relative;
          top: -1px;
          &:before {
            font-size: 0;
          }
        }
      }
    }
  }


}