.pageTop{
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 99;
  @include mq-max(xs) {
    bottom: 20px;
    right: 20px;
  }
  &:after {
    @include fa('f077');
    display: inline-block;
    color: #fff;
    padding: 18px;
    background-color: $keyColor;
    border-radius: 3px;
    line-height: 1;
  }
}
