//ボックス

$boxColor: #f1f1f1;

.box {
  border: 4px solid $boxColor;
  padding: 30px;
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }
  @include mq-max(xs) {
    padding: 20px;
  }
  &_title {
    font-size: 24px;
    font-family: $sanserif;
    margin-bottom: 15px;
    @include mq-max(xs) {
      font-size: 20px;
    }
  }

  //塗りつぶし
  &_fill {
    background-color: $boxColor;
  }
}