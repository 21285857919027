//全体共通で使うCSS定義
html {
  &.open {
    overflow: hidden;
    body {
      overflow: hidden;
    }
  }
}

body {
  color: #000;
  font-family: $sanserif;
  line-height: $lineHeight;
  word-wrap: break-word;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-feature-settings: "pwid";
  @if $is-fixed == true {
    margin-top: $headerHeight;
    @include mq-max(md) {
      margin-top: $headerHeight-sp;
    }
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  見出し

///////////////////////////////////////////////////////////////////////////////////////////////////////
//h2,h3,h4,h5 {}
h1 {
  font-size: 30px;
  @include mq-max(xs) {
    font-size: 24px;
  }
}
h2 {
  font-size: 28px;
  margin-bottom: (40em / 28);
  line-height: 1.5;
  @include mq-max(xs) {
    font-size: 22px;
    margin-bottom: 24px;
  }
}
h3 {
  font-size: 30px;
  margin-bottom: 40px;
  line-height: 1.5;
  font-family: $serif;
  @include mq-max(xs) {
    font-size: 24px;
    margin-bottom: 20px;
  }
}
h4 {
  font-size: 24px;
  margin-bottom: 30px;
  @include mq-max(xs) {
    font-size: 18px;
    margin-bottom: 5px;
  }
}
h5 {
  font-size: 16px;
  margin-bottom: 10px;
  @include mq-max(xs) {
    font-size: 16px;
    margin-bottom: 10px;
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  文章

///////////////////////////////////////////////////////////////////////////////////////////////////////
a {
  text-decoration: none;
  color: $keyColor;
  &[href^="javascript:void(0)"] {
    pointer-events: none;
  }
  &[target="_blank"]{
    &:after{
      @include fa('f24d');
      font-weight: 400;
      margin-left: 10px;
      -webkit-font-smoothing:antialiased;
    }
  }
  &[href$=".pdf"] {
    &:after {
      @include fa('f1c1');
      font-weight: 400;
      color: $red;
      margin-left: 10px;
      -webkit-font-smoothing:antialiased;
    }
  }
  &:not(.btn) {
    color: $linkColor;
    transition: color $transition;
    &:hover {
      color: $keyColor;
    }
  }
}

p {
  font-size: 18px;
  margin-bottom: 1.3em;
  line-height: 1.8;
  color: $textColor;
  &:last-child {
    margin-bottom: 0;
  }
  @include mq-max(xs) {
    font-size: 15px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &.small {
    font-size: 16px;
  }
  &.medium {
    font-size: 20px;
  }
  &.medium {
    font-size: 20px;
  }
  &.large {
    font-size: 24px;
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  強調

///////////////////////////////////////////////////////////////////////////////////////////////////////
strong {
  font-weight: 600;
  //黄色い下線
  &.bdr_yellow {
    background: linear-gradient(transparent 60%, #FFEF69 60%);
  }

  //赤
  &.red {
    color: $keyColor;
  }

  //最も強調する
  &.largest {
    font-size: 150%;
  }
}

.p_large {
  line-height: 3;
  font-size: 120%;
  text-align: center;
}



///////////////////////////////////////////////////////////////////////////////////////////////////////

//  画像

///////////////////////////////////////////////////////////////////////////////////////////////////////
figure {
  img {
    width: 100%;
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  電話番号CSS

///////////////////////////////////////////////////////////////////////////////////////////////////////
.tel-num {}



