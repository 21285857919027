///////////////////////////////////////////////////////////////////////////////////////////////////////

//  ボタン

///////////////////////////////////////////////////////////////////////////////////////////////////////

.btn {
  display: inline-block;
  padding: 14px 38px;
  color: #fff;
  background-color: #000;
  border-radius: 4px;
  border: 1px solid #000;
  transition: all .2s ease;
  max-width: 100%;
  font-weight: 500;
  letter-spacing: 1px;
  box-shadow: $box-shadow;
  &:hover {
    color: #000;
    background-color: #fff;
    box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.06);
  }
  @include mq-max(xs) {
    display: block;
    text-align: center;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &_red {
    background-color: $keyColor;
    border: 1px solid $keyColor;
    &:hover {
      color:  $keyColor;
      background-color: #fff;
    }
  }
  &_white {
    background-color: #fff;
    color: #000;
    border: 1px solid #fff;
  }
  &_gray {
    background-color: #F1F1F1;
    color: #000;
    border: 1px solid #fff;
    &:hover {
      background-color: #F1F1F1;
    }
  }

  //アイコン付きボタン
  &_ico {
    font-size: 20px;
    box-shadow: none;
    color: $keyColor;
    background-color: #fff;
    padding: 22px 60px 22px 100px;
    border: 1px solid $keyColor;
    border-radius: 5px;
    position: relative;
    &:hover {
      color: #fff;
      background-color: $keyColor;
    }
    @include mq-max(xs) {
      text-align: left;
      font-size: 18px;
      padding: 22px 60px 22px 60px;
      box-shadow: none;
    }
    &:before {
      content: '';
      width: 50px;
      height: 50px;
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 38px;
      transform: translateY(-50%);
      @include mq-max(xs) {
        width: 35px;
        height: 35px;
        left: 15px;
      }
    }

    //食品卸し
    &.ico-food {
      &:before {
        background:url(/inc/image/common/ico/ico_food.png)no-repeat center / contain;
      }
    }
    //商品開発ソリューション
    &.ico-solution {
      &:before {
        background:url(/inc/image/common/ico/ico_solution.png)no-repeat center / contain;
      }
    }
  }

}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  PFWForm

///////////////////////////////////////////////////////////////////////////////////////////////////////
.btn_area{
  max-width: $contentWidth;
  margin: 40px auto;
  text-align: center;
  @include mq-max(md) {
    margin: 0 auto;
  }
}

button.btn_form,
a.btn_form{
    min-width: $btn-width;
    padding: 20px 40px;
    margin-bottom: 10px;
    font-size: 130%;
    display: inline-block;
    background: $keyColor;
    text-align: center;
    border: $border-btn-width solid  $border-btn;
    color: $border-txt-color;
    transition: $transition;
    border-radius: $border-radius;
    text-decoration: none;
    position: relative;
    @include mq-max(sm) {
      display: inline-block;
      min-width: inherit;
      width: 100%;
      margin: 0 0 20px 0;
    }

    &:before{
      content: "\f054";
      font-family: "FontAwesome";
      position: absolute;
      right: 20px;
      top: 24px;
    }

    &:hover{
      background: darken( $keyColor, 15% );
    }

    &.back{
      width: auto;
      min-width: inherit;
      background: $white;
      border: 1px solid #ccc;
      color: $black;
      font-size: 120%;
      padding-left: 60px;
      margin-right: 20px;
      @include mq-max(sm) {
        display: inline-block;
        min-width: inherit;
        width: 100%;
        margin: 0 0 20px 0;
      }

      &:hover{
        background: lighten( $gray, 15% );
      }

      &:before{
        content: "\f053";
        right: inherit;
        left: 20px;
      }
    }
}
