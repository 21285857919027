//サイトレイアウト構造を記述

#container {}
#main {}
#contents {
  padding: 150px 0 100px;//お好みで変更
  @include mq-max(sm) {
    padding: 50px 0;//お好みで変更
  }
}

//gridlex調整
[class*="grid"] {
  @media screen and (max-width:$contentWidth + $contentPadding) {
//    margin: 0; //不要かどうか観察中
  }
  // [class$="sm-12"] {
  //   @include mq-max(sm) {
  //     padding-bottom: $contentPadding * 2;
  //   }
  // }
  // [class$="xs-12"] {
  //   @include mq-max(xs) {
  //     padding-bottom: $contentPadding * 2;
  //   }
  // }
}
[class*="col-"] {
  .imgFrame {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  img {
    width: 100%;
    display: block;
  }

}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  基本ブロックレイアウト

///////////////////////////////////////////////////////////////////////////////////////////////////////
.section {
  width: 100%;
  max-width: $contentWidth;
  margin: 0 auto;
  padding-bottom: 120px;//お好みで変更
  &:last-child {
    padding-bottom: 0;
  }
  @media screen and (max-width:$contentWidth + $contentPadding) {
    padding: 0 $contentPadding 60px;
  }
  @include mq-max(sm) {
    padding: 0 20px 60px;//お好みで変更
  }
  & .section {
    padding: 0 0 60px;//お好みで変更
    &:last-child {
      padding-bottom: 0;
    }
  }
}

//background full width(間隔初期値90px)
@mixin bg_scss($space:90px) {
  padding: $space 0;//お好みで変更
  margin-bottom: $space;
  &:last-child {
    margin-bottom: 0;
  }
  @include mq-max(sm) {
    margin: 0 0 $space;//お好みで変更
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.bg_gray {
  background-color: #F1F1F1;
  @include bg_scss();
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  font

///////////////////////////////////////////////////////////////////////////////////////////////////////

//デフォルトフォント
.font_default {
  font-family: $sanserif;
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  text-align

///////////////////////////////////////////////////////////////////////////////////////////////////////
.taL {
  text-align: left !important;
}
.taC {
  text-align: center !important;
}
.taR {
  text-align: right !important;
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  only系

///////////////////////////////////////////////////////////////////////////////////////////////////////
.only-xs {
  display: none !important;
  @include mq-max(xs) {
    display: block !important;
  }
}
.only-sm {
  display: none !important;
  @include mq-max(sm) {
    display: block !important;
  }
  @include mq-max(xs) {
    display: none !important;
  }
}
.only-md {
  display: none !important;
  @include mq-max(md) {
    display: block !important;
  }
  @include mq-max(sm) {
    display: none !important;
  }
}
.only-lg {
  @include mq-max(md) {
    display: none !important;
  }
}

.only-sm-xs {
  display: none !important;
  @include mq-max(sm) {
    display: block !important;
  }
}

.not-xs {
  @include mq-max(xs) {
    display: none !important;
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  その他

///////////////////////////////////////////////////////////////////////////////////////////////////////
.clearfix:after {
  content: '';
  display: table;
  clear: both;
}

//アンカーリンク先につける（位置調整）
.anchorPosition {
  padding-top: 180px;
  margin-top: -180px;
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  layout

///////////////////////////////////////////////////////////////////////////////////////////////////////

//互い違い
.alternate {
  margin-bottom: 0 + (-1 * $contentPadding);
  &_wrap {
    margin-bottom: 50px;
    &:last-child {
      margin-bottom: 0;
    }
    @include mq-max(xs) {
      margin-bottom: 0;
    }
    .image {
      @include mq-max(xs) {
        padding-bottom: $contentPadding / 2;
      }
    }
  }

  //重なるバージョン
  &_overlay {
    margin-bottom: 0;
    .alternate_wrap {
      display: flex;
      margin-bottom: 50px + 65px;
      &:last-child {
        margin-bottom: 0;
      }
      @include mq-max(xs) {
        margin-bottom: 65px;
        flex-direction: column;
      }
      .image {
        width: 60%;
        @include mq-max(xs) {
          width: 100%;
        }
        img {
          width: 100%;
        }
      }
      .card {
        width: 70%;
        opacity: .9;
        margin-left: -150px;
        margin-top: 65px;
        @include mq-max(xs) {
          width: 100%;
          margin: 0;
          opacity: 1;
        }
      }

      //反転
      &.reverse {
        flex-direction: row-reverse;
        @include mq-max(xs) {
          flex-direction: column;
        }
        .card {
          margin-left: 0;
          margin-right: -150px;
        }
      }
    }

    .checkList {
      &_item {
        padding: 0;
      }
      &_head {
        background: linear-gradient(53.95143930717654deg, rgba(202, 97, 79,1) 18.59765625%,rgba(202, 97, 79,1) 19.15581597222222%,rgba(178, 27, 44,1) 52.08723958333333%);
      }
      &_body {
        padding: 30px 20px;
        ul {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          @include mq-max(sm) {
            margin-bottom: 0;
          }
          @include mq-max(xs) {
            flex-direction: column;
          }
          li {
            font-size: 20px;
            width: 48%;
            padding-bottom: 15px;
            border-width: 6px;
            @include mq-max(sm) {
              font-size: 16px;
            }
            @include mq-max(xs) {
              width: 100%;
            }
          }
        }
      }
    }

    &_title {
      font-size: 24px;
      color: $keyColor;
      text-align: center;
      margin-bottom: 35px;
      letter-spacing: 1px;
      @include mq-max(sm) {
        font-size: 18px;
      }
      @include mq-max(xs) {
        text-align: left;
      }
    }
  }
}


