//テキストリンク

//アンカー
.anchor {
  &_bottom {
    &:after {
      @include fa('f107');
      margin-left: 8px;
      display: inline-block;
    }
  }
}