header {
  background-color: $white;
  @if $is-fixed == true {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
  }
  .header_inner {
    width: 100%;
    position: relative;
    z-index: 100;
    @include mq-max(md) {
      display: block;
      padding: 0;
      position: fixed;
      top: 0;
      background: transparent;
    }
  }

  h1,.logo {
    margin-bottom: 0;
    margin-right: 30px;
    line-height: 1;
    display: inline-block;
    width: 350px; // 状況によって変更可
    @include mq-max(md) {
      margin: 0;
      line-height: 0;
    }
    @include mq-max(md) {
      width: 200px;
    }
    img {
      width: 100%;
    }
  }
  .logo {
    img {
      opacity: 1;
      transition: opacity $transition;
    }
    &:hover {
      img {
        opacity: .7;
      }
    }
  }


  .navHeader {
    position: relative;
    max-width: $contentWidth;
    margin: 0 auto;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include mq-max(md) {
      padding: 20px 15px;
      background-color: $white;
      line-height: 0;
    }
  }
  .header_tel {
    color: $keyColor;
    font-weight: 600;
    display: flex;
    align-items: center;
    @include mq-max(md) {
      display: none;
    }
    &:before {
      content: '';
      background: url(/inc/image/common/ico/ico_tel.png)no-repeat center / contain;
      width: 50px;
      height: 50px;
      display: inline-block;
      margin-right: 10px;
    }
    &_title {
      display: block;
      font-size: 12px;
    }
    &_number {
      display: block;
      font-size: 22px;
    }
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////

  //  ハンバーガーメニュー

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  #toggle {
    display: none;
    @include mq-max(md) {
      display: block;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      width: 42px;
      height: 42px;
      cursor: pointer;
      z-index: 3;
      span{
        display: block;
        position: absolute;
        width: 30px;
        border-bottom: solid 3px $black;
        transition: .35s ease-in-out; //変化の速度を指定
        left: 6px;
        &:nth-child(1){
          top: 9px;
        }
        &:nth-child(2){
          top: 18px;
        }
        &:nth-child(3){
          top: 27px;
        }
      }
      &.active{
        span{
          &:nth-child(1){
            top: 18px;
            left: 6px;
            transform: rotate(-45deg);
          }
          &:nth-child(2),&:nth-child(3){
            top: 18px;
            transform: rotate(45deg);
          }
        }
      }
    }
  }
}
