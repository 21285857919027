ul {
  list-style-type: none;
  color: $textColor;
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }

  //disc
  &.disc {
    position: relative;
    padding-left: 1em;
    li {
      &:before {
        content: '・';
        display: inline-block;
        color: $keyColor;
        position: absolute;
        left: -8px;
        font-size: 25px;
        line-height: 1;
      }
    }
  }

  //count
  &.count{
    counter-reset: count;
    &>li{
      padding-left: 1em;
      position: relative;
      &:before{
        counter-increment: count;
        content:counter(count);
        position: absolute;
        left: 0;
      }
    }
  }

  //注釈
  &.comment{
    li{
      // display: flex;
      padding-left: 1em;
      position: relative;
      &:before{
        content: '※';
        display: inline-block;
        position: absolute;
        left: 0;
      }
    }
  }
}

//チェックリスト
.checkList {
  margin: 0 -1*$contentPadding/2;
  margin-bottom: 110px;
  @include mq-max(xs) {
    margin-bottom: 50px;
  }
  &_item {
    padding: 0 22.5px;
    @include mq-max(xs) {
      padding: 0 ($contentPadding/2) $contentPadding;
    }
  }

  &_head {
    background-color: #A42020;
    padding: 26px 0 20px;
  }
  &_title {
    font-family: $sanserif;
    text-align: center;
    font-size: 39px;
    color: #fff;
    margin: 0;
    @include mq-max(sm) {
      font-size: 30px;
    }
    @include mq-max(xs) {
      font-size: 24px;
    }
    &>span {
      font-size: 16px;
      text-align: center;
      display: block;
      line-height: 1;
      margin-bottom: 8px;
    }
  }

  &_body {
    background-color: #fff;
    padding: 60px 45px;
    box-shadow: $box-shadow;
    @include mq-max(sm) {
      padding: 30px 20px;
    }
    ul {
      li {
        font-size: 19px;
        border-bottom: 10px solid #F1F1F1;
        padding-bottom: 25px;
        margin-bottom: 25px;
        position: relative;
        display: flex;
        color: #000;
        font-weight: 600;
        @include mq-max(xs) {
          font-size: 16px;
        }
        &:before {
          @include fa('f00c');
          display: inline-block;
          color: $keyColor;
          font-size: 19px;
          margin-right: 12px;
        }
      }
    }
  }
}