footer {
  background-color: #221b1e;
  .f_inner {
    max-width: $contentWidth;
    width: 100%;
    margin: 0 auto;
    padding: 70px 0 30px;
    display: flex;
    @include mq-max(sm) {
      padding: 50px 0;
    }
    @include mq-max(xs) {
      flex-wrap: wrap;
      margin-bottom: -30px;
    }

    .f_wrap {
      color: $white;
      width: 100%;
      margin: 0;
    }

    .f_logo {
      max-width: 275px;
      margin-bottom: 20px;
    }

    .f_info,.f_link {
      font-size: 14px;
      color: $footerTxtColor;
      li {
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .f_info {
      li {
        position: relative;
        padding-left: 30px;
        &:before {
          position: absolute;
          top: 2px;
          left: 0;
        }
        &.address {
          &:before {
            @include fa('f3c5');
          }
        }
        &.tel {
          &:before {
            @include fa('f879');
          }
          .tel_num {
            a {
              color: inherit;
            }
          }
        }
        &.fax {
          &:before {
            @include fa('f0e0');
          }
        }
      }
    }
    .f_link {
      @include mq-max(xs) {
        padding-left: 30px;
      }
      li {
        a {
          color: $footerTxtColor;
          &:hover {
            color: $keyColor;
          }
          &:before {
            @include fa('f054');
            display: inline-block;
            margin-right: 8px;
          }
        }
      }
    }

    .f_others {
      &_title {
        font-size: 17px;
        margin-bottom: 25px;
      }
      .f_hinmoku {
        border: 2px solid #fff;
        padding: 18px 25px;
        font-size: 12px;
        color: $footerTxtColor;
      }
      .f_bnr {
        li {
          margin-bottom: 14px;
          &:last-child {
            margin-bottom: 0;
          }
          a {
            transition: opacity $transition;
            display: block;
            &:after {
              content: none;
            }
            &:hover {
              opacity: .7;
            }
            img {
              width: 100%;
            }
          }
        }
      }
    }

  }

  //Copyright
  .copyright {
    text-align: center;
    display: block;
    color: $white;
    background-color: $black;
    font-size: 12px;
    padding: 35px 0;
  }
}
