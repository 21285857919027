//slick-arrow

//slick
@mixin slickArrows() {
  width: 40px;
  height: 40px;
  background-color: #fff;
  box-shadow: -1px 2px 5px 2px rgba(0,0,0,.1);
  z-index: 10;
  top: 50%;
  @include mq-max(bp600) {
    top: 45%;
  }
  &:before {
    color: #000;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    opacity: 1;
  }
  &:hover,&:visited,&:focus {
    background-color: #fff;
    &:before {
      color: #000;
    }
  }
}

.slick-arrow {
  &.slick-prev {
    @include slickArrows;
    left: -60px;
    @include mq-max(bp1350) {
      left: -10px;
    }
    &:before {
      @include fa('f104');
    }
  }
  &.slick-next {
    @include slickArrows;
    right: -60px;
    @include mq-max(bp1350) {
      right: -10px;
    }
    &:before {
      @include fa('f105');
    }
  }
}