//テーブル
.tbl {
  max-width: 100%;
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }
  th,td {
    border: 1px solid #EDEDED;
    padding: 25px;
    font-size: 18px;
    @include mq-max(sm) {
      font-size: 16px;
    }
  }
  th {
    background-color: #333333;
    color: #fff;
  }
  td {
    color: $textColor;
  }

  //デフォルト
  &_block {
    tbody{
      tr{
        @include mq-max(xs){
          &:not(:last-child){
            td{
              border-bottom: none;
            }
          }
        }
        th{
          width: 20%;
          @include mq-max(xs){
            border-bottom: none !important;
          }
        }
        td{
          width: 80%;
        }
        th,td{
          @include mq-max(xs){
            display: block;
            width: 100%;
          }
        }
      }
    }
  }

  //スクロール型
  &_scroll {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
    @include mq-max(xs) {
      overflow: hidden;
      overflow-x: scroll;
      padding: 15px;
      border: 1px solid #cecece;
      border-radius: 5px;
      &:before {
        content: "←スライドで表の詳細を確認できます→";
        color: #666;
        font-size: 90%;
        display: block;
        margin-bottom: 10px;
        text-align: center;
      }
      table {
        min-width: $contentWidth;
        th, td {
          display: table-cell;
        }
      }
    }
    table {
      thead {
        th {
          white-space: nowrap;
          &:first-child {
            width: 30%;
          }
        }
      }
      tbody {
        th {
          background-color: #F8F8F8;
          color: $textColor;
        }
      }
    }
  }

}