#gnavi {
  border-top: 1px solid $bdrColor;
  border-bottom: 1px solid $bdrColor;
  @include mq-max(md) {
    display: none;
    border-bottom: none;
    html.open &.active > ul {
      overflow-y: scroll;
      height: calc(100vh - #{$headerHeight-sp});//画面高さ - fixedヘッダーの高さ
      padding-bottom: 120px;
    }
  }
  &.active {
    transform: translateY(0%);
  }
  &>ul {
    padding: 0;
    display: flex;
    max-width: $contentWidth;
    margin: 0 auto;
    @include mq-max(md) {
      display: block;
    }
    &>li {
      position: relative;
      width: calc(100% / 6);
      text-align: center;
      border-left: 1px solid $bdrColor;
      &:last-child {
        border-right: 1px solid $bdrColor;
      }
      @include mq-max(md) {
        width: 100%;
        border-top: 1px solid $gray;
        border-left: none;
        &:last-child{
          border-bottom: 1px solid $gray;
          border-right: none;
        }
      }
      //dropdown ico
      &.drop_down {
        &>a {
          &:after {
            @include fa('f107');
            color: #cccccc;
            position: absolute;
            top: 52%;
            right: -18px;
            transform: translateY(-50%);
          }
        }
      }
      &>a {
        text-decoration: none;
        color: #000;
        font-size: 16px;
        font-weight: 600;
        display: inline-block;
        padding: 22px 0;
        @include mq-max(md) {
          display: block;
          padding: 15px 10px;
          background-color: $white;
        }
        &:hover {
          color: $keyColor;
        }
        &.current {
          color: $keyColor; // 現在地
        }
      }

      //下層メニュー
      &.drop_down {
        &>a{
          position: relative;
          &:after {
            @include mq-max(md) {
              @include fa('f067');
              position: absolute;
              top: 50%;
              right: 16px;
              transform: translateY(-50%);
            }
          }
          &.active {
            &:after {
              @include mq-max(md) {
                content: '\f068';
              }
            }
          }
        }
        &.active {
          .drop_down_menu {
            top: $gnaviHeight - 1px; // gnaviの高さ
            opacity: 1;
            visibility: visible;
            @include mq-max(md) {
              top: auto;
            }
            a {
              transition: color $transition;
              &:hover {
                color: $keyColor;
              }
            }
          }
        }
        .drop_down_menu {
          position: absolute;
          top: ($gnaviHeight - 30px); // gnaviの高さ - 30px(変化の度合い)
          visibility: hidden;
          opacity: 0;
          transition: all .5s ease;
          width: 100%;
          @include mq-max(md) {
            position: relative;
            display: none;
            transition: none;
            top: auto;
            opacity: 1;
            visibility: visible;
          }
          li {
            border-bottom: 1px solid $bdrColor;
            &:first-child {
              border-top: 1px solid $bdrColor;
              @include mq-max(md) {
                border-top: 1px solid $bdrColor;
              }
            }
            &:last-child {
              border: none;
            }
            a {
              background-color: $white;
              padding: 10px 15px;
              white-space: nowrap;
              display: block;
              text-decoration: none;
              color: #000;
              width: 100%;
              @include mq-max(md) {
                padding: 15px 10px 15px 25px;
              }
            }
          }
        }
      }
    }
  }
}
