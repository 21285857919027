/*
モジュール系おまとめファイル
*/

// ヘッダー
@import "header";

// フッター
@import "footer";

// グローバルナビゲーション
@import "navigation";

 // ページトップに戻る
@import "pageTop";

 // ボタン
@import "button";

//テーブル
@import "table";

//リスト
@import "list";

//見出し
@import "heading";

//アコーディオン
@import "accordion";

//タブ
@import "tab";

//沿革
@import "timeline";

//お知らせ
@import "newslist";

//テキストリンク
@import "link";

//ボックス
@import "box";

//スライダーリンク（JS）
@import "sliderLink";