///////////////////////////////////////////////////////////////////////////////////////////////////////

//お知らせ一覧

///////////////////////////////////////////////////////////////////////////////////////////////////////

@import "slick-arrow";

.news_component {
  position: relative;
  margin-bottom: 80px;
  &:last-child {
    margin-bottom: 0;
  }
  @include mq-max(sm) {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
  }
  .en_heading {
    margin-bottom: 35px;
    @include mq-max(sm) {
      text-align: center;
    }
  }
  .toArchive_btn {
    position: absolute;
    top: 12px;
    right: 0;
    @include mq-max(sm) {
      position: static;
      order: 3;
      text-align: right;
    }
    .btn {
      display: inline-block;
    }
  }
}

.newslist {
  display: flex;
  justify-content: space-between;
  margin: 0 -20px 30px;
  @include mq-max(sm) {
    margin: 0;
    margin-bottom: 40px !important;
  }

  &_item {
    padding: 0 20px;
    @include mq-max(sm) {
      padding: 0 5px;
    }
    @include mq-max(xs) {
      padding: 0 20px;
    }
    &>a {
      display: block;
      &:after {
        content: none;
      }
      &:hover {
        .newslist_thumb {
          img {
            transform: scale(1.2);
          }
        }
      }
    }
  }

  &_thumb {
    line-height: 1;
    border-radius: 10px;
    overflow: hidden;
    img {
      width: 100%;
      display: block;
      transition: transform $transition;
    }
  }

  &_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0 5px;
  }
  &_date {
    font-size: 15px;
    color: #8A8A8A;
  }
  &_cat {
    font-size: 11px;
    color: #E02222;
    border: 1px solid $keyColor;
    padding: 0 15px;
  }
  &_title {
    font-family: $sanserif;
    font-size: 20px;
    margin-bottom: 15px;
    color: #000;
    @include mq-max(sm) {
      font-size: 18px;
    }
    &+p {
      font-size: 14px;
    }
  }

}