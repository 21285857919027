///////////////////////////////////////////////////////////////////////////////////////////////////////

//  見出しクラス

///////////////////////////////////////////////////////////////////////////////////////////////////////

//英語見出し
.en_heading {
  color: #000;
  font-size: 39px;
  margin-bottom: 25px;
  font-weight: 800;
  @include mq-max(xs) {
    font-size: 26px;
  }
  &>span {
    font-size: 16px;
    display: block;
    font-weight: 600;
  }
}

//日本語＋英語
.jp_heading {
  color: #fff;//
  font-size: 45px;
  margin-bottom: 35px;
  &>span {
    display: block;
    font-size: 21px;
  }
}

//キャッチコピー
.catchcopy {
  font-size: 38px;
  font-weight: 400;
  margin-bottom: 35px;
  @include mq-max(xs) {
    font-size: 25px;
  }
}

//セクションタイトル
.section_title {
  color: $keyColor;
  text-align: center;
  font-size: 39px;
  font-weight: 600;
  margin-bottom: 45px;
  @include mq-max(xs) {
    font-size: 26px;
  }
  &>span {
    display: block;
    font-size: 16px;
    margin-top: 15px;
  }
}